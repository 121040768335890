<template>
  <h3 class="text-light mt-3">Perfil</h3>
  <div class="row justify-content-center">
    <div class="col-md-4">
      <CreateUserForm :view="view" />
    </div>
  </div>
</template>

<script>
import {  inject, ref } from "vue-demi";
import CreateUserForm from "../modules/usuarios/components/CreateUserForm.vue";
// import { useStore } from "vuex";
import useAuth from "../modules/auth/composable/useAuth"
export default {
  name: "Perfil",
  components: {
    CreateUserForm,
  },
  setup() {
    const objUser = inject("objUser");
    const imageUrl = inject("imageUrl");
    const view = ref("perfil");
    // const store = useStore();
    const {user} = useAuth();


    objUser.id = user.value.id;
    objUser.name = user.value.name;
    objUser.email = user.value.email;
    objUser.telefono = user.value.telefono;
    objUser.role_id = user.value.role.id;
    objUser.password = "123456";
    objUser.password_confirmation = "123456";
    objUser.status = user.value.status;
    objUser.tmp = user.value.tmp;
    imageUrl.value = user.value.foto
      ? process.env.VUE_APP_URL_IMG + user.value.foto
      : null;

    return { view };
  },
};
</script>

<style>
</style>